@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Source+Sans+Pro:wght@300&display=swap');

* {
    margin: 0 auto;
    padding: 0 auto;
}  
  :root {
    --color1: #e3f2fd;
    --color2: #bbdefb;
    --color3: #90caf9;
    --color4: #64b5f6;
    --color5: #42a5f5;
    --color6: #2196f3;
    --color7: #1e88e5;
    --color8: #1976d2;
    --color9: #1565c0;
    --color10: #0d47a1;
    --titlefont: 'Bebas Neue', cursive;
    --textfont: 'Source Sans Pro', sans-serif;
  }

.headercontainer, .footercontainer {
    padding: 6px;
    text-align: center;
    background-color: var(--color10);
    color: var(--color1);
}

.navcontainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap
}

.navbar {
    text-decoration: none;
    padding: 5px;
    color: var(--color2);;
    &:hover {
    color: var(--color3);; 
    }  
}

.mainheader {
    margin: 2rem 0 3rem 0;
}

.background {
    background-color: var(--color1);
}

.square {

        border: 5px solid var(--color10);
        width: 19rem;
        height: 19rem;
        margin: 6rem auto;
        background-color: var(--color8);
        transition: background-color 2s ease-out;


    &:before {
        content: "";
        width: 18rem;
        height: 18rem;
        position: absolute;
        border: 5px solid var(--color10);
        transform: rotate(45deg);
        background-color: var(--color8);
        transition: background-color 3s ease-out;
    }

    &:after {
        
        content: "";
        background-image: url(https://i.imgur.com/47btam2.png);
        border: 5px solid var(--color10);
        background-size: 18rem;
        width: 18rem;
        height: 18rem;
        position: absolute;
        border-radius: 50%;
    }
}

.tagline {
    text-align: center;
    color: var(--color10); 
}

.projectsection {
    background-color: var(--color1);
    text-align: center;
    color: var(--color10);
}


.projectcontainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14rem, max-content));
    grid-Gap: 16px;
    justify-content: center;
    background: var(--color1); 
}
.projectcard {
    width: 300px;
    height: 32rem;
    text-align: center;
    font-weight: 900;
    margin: 10px;
    border: 3px solid var(--color10);
    border-radius: 20px;
    background-color: var(--color6);
    padding: 10px;
    color: var(--color10);
}

.projectimage {
    width: 250px;
    border-radius: 40px;
    border: 3px solid var(--color10);
}

.projectdescription {
    margin: 6px;
}

.projectlinkcontainer {
    display: flex;
    margin: auto;
    justify-content: center; 
}

.projectlink {
    width: 40%;
    padding: 6px;
    font-weight: 800;
    margin: 5px;
    text-decoration: none;
    font-size: 1.1em;
    border-radius: 20px;
    &:hover {
        background-color: var(--color10);
        color: var(--color3);
    }
    background-color: var(--color2);
    border: 3px solid var(--color10);
    color: var(--color10); 
}
.skillssection {
    background-color: var(--color8);
    text-align: center;
    color: var(--color1);
}

.skillscontainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, max-content));
    grid-Gap: 16px;
    justify-content: center;
    background: var(--color8);
}
.skillscard {
    width: 20rem;
    height: 10rem;
    text-align: center;
    margin: 2px;
    border: 3px solid var(--color10);
    border-radius: 20px;
    background-color: var(--color2);
    padding: 10px;
    color: var(--color10);
}
.skillslist {
    padding: 5px;
    width: 15rem;
    border-radius: 20px;
    background-color: var(--color1);
    padding: 10px;
    color: var(--color10);  
}

.skillsstyle{
    margin: 0;
    background: var(--color1);
}

.contactmecontainer {
    background-color: var(--color1);
    text-align: center;
    color: var(--color10);
}

.contactlink {
    width: 75px;
    padding: 0 20px 0 20px;
}
.aboutmesection {
    background-color: var(--color8);
    text-align: center;
    color: var(--color1);
}

.aboutmeimage {
    max-width: 300px;
    border: 6px solid var(--color10);
    text-align: center;
    border-radius: 10px;

}

.brandstatementcontainer {
    background-color: var(--color8);
    text-align: center;
    color: var(--color1)
}

.brandstatement {
    font-size: 1.5rem;
    margin: 0 30% 0 30%;
    text-indent: 3rem;
    text-align: left;
}


@media only screen and (max-width: 600px) {
    .square {
        width: 17rem;
        height: 17rem;

        &:before {
            width: 16rem;
            height: 16rem;
        }

        &:after {
            background-size: 16rem;
            width: 16rem;
            height: 16rem;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .brandstatement {
        margin: 0 5% 0 5%; 
    }
}